export interface IGraphEdges<T> {
  edges: IGraphNode<T>[];
}

export interface IGraphNode<T> {
  node: T;
}

interface IGraphNodeWithPagination<T> {
  cursor: string;
  node: T;
}

interface IPageInfo {
  hasNextPage: boolean;
}

export interface IPaginatedGraphEdges<T> {
  pageInfo: IPageInfo;
  edges: IGraphNodeWithPagination<T>[];
}

export function getNextCursor<T>(paginatedGraphEdges: IPaginatedGraphEdges<T>): string | null {
  if (!paginatedGraphEdges?.pageInfo) {
    throw new Error("no 'pageInfo' in query");
  }

  if (!paginatedGraphEdges.pageInfo.hasNextPage || !paginatedGraphEdges.edges.length) {
    return null;
  }

  return paginatedGraphEdges.edges[paginatedGraphEdges.edges.length - 1].cursor;
}
