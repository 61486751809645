import { ApolloQueryResult } from "@apollo/client";

import { IPaginatedGraphEdges, getNextCursor } from "../../interfaces/GraphQL";
import { AppReqResponse } from "../../req";
import { paginatedRequestStream } from "../../utils/paginatedRequestStream";

export function paginatedRequestStreamShopifyGraphQL<
  TopLevelResType,
  PaginatedDataType,
  AdditionalDataType = undefined
>(
  callWithCursor: (currentCursor: string | null) => Promise<AppReqResponse<ApolloQueryResult<TopLevelResType>>>,
  accessNestedPaginated: (res: ApolloQueryResult<TopLevelResType>) => IPaginatedGraphEdges<PaginatedDataType>,
  onResponse: (
    response: ApolloQueryResult<TopLevelResType>,
    currentCursor: string | null,
    additionalData: AdditionalDataType
  ) => Promise<any>,
  startingCursor: string | null = null,
  additionalData?: AdditionalDataType,
  parallelize = true
): Promise<AppReqResponse<null>> {
  return paginatedRequestStream<ApolloQueryResult<TopLevelResType>, string, AdditionalDataType>(
    callWithCursor,
    res => getNextCursor(accessNestedPaginated(res)),
    onResponse,
    startingCursor,
    additionalData,
    parallelize
  );
}
